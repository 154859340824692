import { TFunction } from 'next-i18next'

export const getReferencesContent = (t: TFunction) => [
  {
    id: 1,
    image: '/assets/images/references/max_fisher.webp',
    title: 'Max Fisher',
    subtitle: 'Brohenmayer GmbH.',
    description: t('references.max_fisher')
  },
  {
    id: 2,
    image: '/assets/images/references/falusi_gabor.webp',
    title: 'Falusi Gábor',
    subtitle: 'Téridom Kft.',
    description: t('references.falusi_gabor')
  },
  {
    id: 3,
    image: '/assets/images/references/jan_slatik.webp',
    title: 'Jan Slatik',
    subtitle: 'Citybus Bus S.r.o.',
    description: t('references.jan_slatik')
  },
  {
    id: 4,
    image: '/assets/images/references/altai_zoltan.webp',
    title: 'Altai Zoltán',
    subtitle: 'Geoplan Zrt.',
    description: t('references.altai_zoltan')
  }
]
