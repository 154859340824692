import styled from 'styled-components'
import { H2 } from '../style-system/typography'
import { fluidFontSize } from '../style-system/utils'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SectionTitle = styled(H2)`
  letter-spacing: 2px;
  text-align: center;
  margin: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${fluidFontSize([28, 28, 36, 36])};
`
