module.exports = {
  i18n: {
    defaultLocale: 'hu',
    locales: ['hu'],
    defaultNS: 'main',
    localeDetection: false,
    react: {
      useSuspense: false
     }
  }
}
