import Head from 'next/head'

const Seo: React.FC = () => {
  const keywords = [
    'túlméretes',
    'túlsúlyos',
    'gépek',
    'szállítás',
    'kamionok',
    'autóbuszok',
    'tehergépjárművek',
    'munkagépek',
    'nehézgépek',
    'traktorok',
    'kombájnok',
    'mezőgazdasági gépek',
    'hídelemek',
    'markolók',
    'daruk',
    'gyártósori gépek',
    'hosszú áruk',
    'belföld',
    'külföld',
    'vontatójármű',
    'nagy teherbírású',
    'tonna',
    'mélyágyas',
    'eu',
    'Európai Unió',
    ' túlméretes szállítmányozás',
    'Ausztria',
    ' Németország',
    'Belgium',
    'Hollandiába',
    'Franciaország',
    'Szlovénia',
    '15 év tapasztalat',
    'transzgfer',
    'gépek'
  ].join(', ')

  return (
    <Head>
      <title>GepTrans.com</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2b5797" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <meta
        name="description"
        content="Túlméretes, túlsúlyos áruk és gépek belföldi, nemzetközi szállítása 15 év tapasztalattal - bármilyen egyedi igény szerint!"
      />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content="https://geptrans.com" />
      <meta property="og:title" content="GepTrans" />
      <meta property="og:type" content="article" />
      <meta
        property="og:description"
        content="Túlméretes, túlsúlyos áruk és gépek belföldi, nemzetközi szállítása 15 év tapasztalattal - bármilyen egyedi igény szerint!"
      />
      <meta property="og:site_name" content="GepTrans.com" />
      <meta
        property="og:image"
        content="https://geptrans.com/assets/images/hero/tulmeretes-szallitas.webp"
      />
      <meta name="robots" content="index,follow" />
    </Head>
  )
}

export default Seo
