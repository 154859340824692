import styled, { css } from 'styled-components'
import { BodyText } from '../../style-system/typography'
import { fluidFontSize } from '../../style-system/utils'

export const HeaderWrapper = styled.header<{ isOnTop: boolean }>`
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: 60px;
  padding: 0 30px;
  transition: all 500ms ease;
  background-color: ${({ theme, isOnTop }) =>
    isOnTop ? theme.colors.whiteWithOpacity(0.4) : theme.colors.white};
  ${({ theme, isOnTop }) =>
    !isOnTop &&
    css`
      box-shadow: 0 -15px 20px ${theme.colors.nightBlue};
    `};
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const NavbarItemGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.query.md} {
    flex-direction: column;
  }
`

export const NavbarItem = styled(BodyText)`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms ease;
  cursor: pointer;
  font-size: ${fluidFontSize([16, 16, 16, 18])};
  color: ${({ theme }) => theme.colors.darkBlue};
  a {
    text-decoration: none;
    transition: color 300ms ease;
    color: ${({ theme }) => theme.colors.darkBlue};
  }
  :hover,
  a:hover {
    color: ${({ theme }) => theme.colors.darkBlueWithOpacity(0.7)};
  }
  & svg {
    cursor: default;
    color: ${({ theme }) => theme.colors.darkBlue};
    margin-right: 8px;
  }
`

export const NavbarItems = styled.div<{ open: boolean }>`
  display: flex;
  height: 100%;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  transition: background-color 500ms ease;
  @media ${({ theme }) => theme.query.md} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    position: fixed;
    width: 280px;
    min-height: 100vh;
    right: ${({ open }) => (open ? 0 : '-290px')};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 100px 0;
    transition: right 0.5s;
    & ${NavbarItem} {
      padding: 16px;
    }
  }
  @media ${({ theme }) => theme.query.sm} {
    width: 100vw;
    right: ${({ open }) => (open ? 0 : '-100%')};
  }
`

export const Hamburger = styled.div<{ open: boolean }>`
  width: 25px;
  height: 2px;
  position: relative;
  background: ${(props) => props.theme.colors.darkBlue};
  transition: transform 0.5s ease;
  :before,
  :after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: -8px;
    background: ${(props) => props.theme.colors.darkBlue};
    transition: transform 0.3s ease;
  }
  :after {
    top: 8px;
  }
  ${({ open }) =>
    open &&
    css`
      background: transparent;
      :before {
        top: 0;
        transform: rotate(-45deg);
      }
      :after {
        top: 0;
        transform: rotate(45deg);
      }
    `}
`

export const MenuButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 11px 0;
  cursor: pointer;
  z-index: 100;
  margin-right: 20px;
  margin-left: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`
