import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'styled-components'
import theme from '../style-system/theme'
import GlobalStyle from '../style-system/GlobalStyles'
import Layout from '../components/Layout'
import nextI18NextConfig from '../../next-i18next.config.js'
import Seo from '../components/Seo'
import { GoogleAnalytics } from '@next/third-parties/google'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <Seo />
      <GoogleAnalytics gaId="G-KP291XRFK6" />
      <GlobalStyle />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ThemeProvider>
  )
}

export default appWithTranslation(MyApp, nextI18NextConfig)
