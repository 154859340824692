import styled from 'styled-components'
import {
  layout,
  space,
  color,
  border,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
  DisplayProps,
  display,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  GridProps,
  grid
} from 'styled-system'
import theme from './theme'

export const Spacer = styled.div<
  SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    GridProps
>`
  ${color};
  ${space};
  ${layout};
  ${flexbox};
  ${position};
  ${grid};
`

export const Box = styled.div<
  LayoutProps & ColorProps & BorderProps & SpaceProps & DisplayProps
>`
  ${layout};
  ${color};
  ${border};
  ${space};
  ${display};
`

export const Divider = styled.div<ColorProps>`
  width: 100%;
  height: 1px;
  background: ${(p) => p.theme.colors.grays[3]};
  margin: 12px 0;
  ${color}
`

export const Section = styled.section<
  LayoutProps & ColorProps & SpaceProps & FlexboxProps & GridProps
>`
  position: relative;

  ${layout};
  ${color};
  ${space};
  ${grid};
  ${flexbox};
`

Section.defaultProps = {
  paddingX: theme.spacing.sectionPaddingX
}
