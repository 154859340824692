import styled from 'styled-components'
import { fluidFontSize } from '../../style-system/utils'
import { SectionContainer } from '../styles.util'

export const Container = styled(SectionContainer)`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.darkBlue};
`

export const Content = styled.div`
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: ${fluidFontSize([14, 16, 18, 20])};
  a {
    display: inline-block;
    color: ${({ theme }) => theme.colors.white};
    transition: all 0.5s ease;
    :hover {
      color: ${({ theme }) => theme.colors.whiteWithOpacity(0.6)};
    }
  }
`

export const OfferRequest = styled.div`
  max-width: 800px;
  align-self: center;
  margin: 15px 0 30px 0;
`

export const DetailsTitle = styled.div`
  font-size: ${fluidFontSize([20, 22, 24, 26])};
  margin-bottom: 15px;
`

export const ContactDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.query.l} {
    flex-wrap: wrap;
  }
  @media ${({ theme }) => theme.query.md} {
    justify-content: space-evenly;
  }
  @media ${({ theme }) => theme.query.sm} {
    justify-content: center;
  }
`

export const Details = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  @media ${({ theme }) => theme.query.md} {
    flex-direction: column-reverse;
  }
`
export const DetailColumn = styled.div`
  flex: 1;
  width: 100%;
`

export const DetailRow = styled.div`
  margin-bottom: 20px;
`

export const IconText = styled.div`
  display: flex;
  svg {
    color: ${({ theme }) => theme.colors.blue};
    margin-right: 10px;
  }
`

const DetailSection = styled.div`
  text-align: left;
  padding: 20px;
  iframe {
    width: 400px;
    border-radius: 8px;
    min-height: 250px;
    margin-right: 50px;
  }
  @media ${({ theme }) => theme.query.md} {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    iframe {
      margin-right: 0;
      margin-top: 15px;
    }
  }
  @media ${({ theme }) => theme.query.sm} {
    margin: 10px 0;
    iframe {
      width: 100%;
      min-height: 200px;
    }
  }
`

export const HeadquartersDetails = styled(DetailSection)``

export const GarageDetails = styled(DetailSection)``

export const Footer = styled.div`
  padding: 30px 20px;
  margin-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: ${fluidFontSize([12, 14, 16, 16])};
`
