import React, { ReactNode } from 'react'
import { Spacer } from '../../style-system/primitives'
import Contact from '../Contact'
import Header from '../Header'

type LayoutProps = {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Spacer display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <main>{children}</main>
      <Contact />
    </Spacer>
  )
}
