import React, { createRef } from 'react'
import { useTranslation } from 'next-i18next'
import { getReferencesContent } from './content'
import {
  Card,
  CardsContainer,
  Content,
  ReferenceImg,
  ReferencesSection,
  Title
} from './styles'

export const ReferencesRef = createRef<any>()

export default function References() {
  const { t } = useTranslation()

  const content = getReferencesContent(t)

  return (
    <ReferencesSection ref={ReferencesRef}>
      <Title>{t('references.title')}</Title>
      <CardsContainer>
        {content.map((reference) => {
          return (
            <Card key={reference.id}>
              <ReferenceImg bgImg={reference.image} />
              <Content>
                <div className="title">{reference.title}</div>
                <div className="subtitle">{reference.subtitle}</div>
                <div className="message">{reference.description}</div>
              </Content>
            </Card>
          )
        })}
      </CardsContainer>
    </ReferencesSection>
  )
}
