import styled from 'styled-components'
import { fluidFontSize } from './utils'

export const H1 = styled.h1`
  font-family: Brandon;
  font-size: ${fluidFontSize([28, 36, 48, 56])};
`

export const H2 = styled.h2`
  font-family: Brandon;
  font-size: 36px;
`

export const H3 = styled.h2`
  font-family: Brandon;
  font-size: 26px;
`

export const BodyText = styled.div`
  font-family: Brandon;
  font-size: ${fluidFontSize([12, 12, 14, 16])};
`

export const SubBodyText = styled.div`
  font-family: Brandon;
  font-size: ${fluidFontSize([12, 12, 12, 14])};
`

export const CaptionText = styled.div`
  font-family: Brandon;
  font-size: 12px;
`
