import styled from 'styled-components'
import { SectionTitle } from '../styles.util'

export const ServicesSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Title = styled(SectionTitle)`
  margin: 25px;
  margin-top: 60px;
  color: ${({ theme }) => theme.colors.darkBlue};
`
