import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ServiceImages from './ServiceImages'
import {
  DescriptonText,
  FullDescription,
  ServiceCarousel,
  ServiceContainer,
  ServiceContent,
  Text,
  Title
} from './styles'

type Props = {
  title: string
  description: string[]
  onImagesClick?: () => void
  images: string[]
}

const Service: React.FC<Props> = ({
  title,
  description,
  images,
  onImagesClick
}) => (
  <ServiceCarousel>
    <ServiceContainer>
      <ServiceImages images={images} onClick={onImagesClick} />
      <ServiceContent style={{ color: 'black' }}>
        <Title>{title}</Title>
        <FullDescription>
          {description.map((text, i) => (
            <DescriptonText key={i}>
              <ArrowForwardIosIcon />
              <Text>{text}</Text>
            </DescriptonText>
          ))}
        </FullDescription>
      </ServiceContent>
    </ServiceContainer>
  </ServiceCarousel>
)

export default Service
