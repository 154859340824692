import { ImagesBox, ImagesContainer, ServiceImg } from './styles'
import 'react-awesome-lightbox/build/style.css'

type Props = {
  images: string[]
  onClick?: () => void
}

const ServiceImages: React.FC<Props> = ({ images, onClick }) => {
  return (
    <>
      <ImagesBox onClick={onClick}>
        <ImagesContainer>
          {images.map((url) => (
            <ServiceImg key={url} src={url} />
          ))}
        </ImagesContainer>
      </ImagesBox>
    </>
  )
}

export default ServiceImages
