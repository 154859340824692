import styled from 'styled-components'

export const TabsContainer = styled.div`
  width: 100%;
  margin: 20px 0 40px 0;
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.query.sm} {
    margin-bottom: 15px;
  }
`
