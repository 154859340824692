import styled from 'styled-components'
import { fluidFontSize } from '../../style-system/utils'
import { SectionContainer, SectionTitle } from '../styles.util'

export const WhyUsSection = styled(SectionContainer)`
  padding: 120px 30px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  @media ${({ theme }) => theme.query.sm} {
    padding: 60px 30px;
  }
`

export const WhyUsContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  row-gap: 60px;
  @media ${({ theme }) => theme.query.md} {
    width: 100%;
  }
`

export const Title = styled(SectionTitle)`
  letter-spacing: 2px;
  width: 30%;
  padding: 0;
  margin: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${fluidFontSize([28, 28, 46, 46])};
  @media ${({ theme }) => theme.query.l} {
    width: 45%;
  }
  @media ${({ theme }) => theme.query.md} {
    width: 100%;
    text-align: center;
  }
  @media ${({ theme }) => theme.query.sm} {
    width: 100%;
  }
`

export const ItemCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 20px;
  padding-top: 35px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  text-align: left;
  @media ${({ theme }) => theme.query.l} {
    width: 45%;
  }
  @media ${({ theme }) => theme.query.md} {
    width: 70%;
  }
  @media ${({ theme }) => theme.query.sm} {
    width: calc(100% - 25px);
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: -20px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  background: ${({ theme }) => theme.colors.darkBlue};
`

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`

export const ItemTitle = styled.div`
  font-size: ${fluidFontSize([16, 18, 20, 20])};
  color: ${({ theme }) => theme.colors.white};
`

export const ItemText = styled.div`
  height: 100%;
  display: flex;
  margin-top: 20px;
  font-size: ${fluidFontSize([14, 16, 18, 18])};
  color: ${({ theme }) => theme.colors.white};
`
