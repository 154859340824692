import styled, { css } from 'styled-components'
import { BodyText } from '../../../../style-system/typography'
import { fluidFontSize } from '../../../../style-system/utils'

export const TabSeparator = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 5px;
  left: calc(-50% - 20px);
  background-color: ${({ theme }) => theme.colors.grayWithOpacity(0.3)};
  @media ${({ theme }) => theme.query.sm} {
    left: calc(-50% - 10px);
  }
`

export const TabLabel = styled(BodyText)`
  max-width: 150px;
  margin: 15px 0;
  text-align: center;
  transition: inherit;
  color: ${({ theme }) => theme.colors.grayWithOpacity(0.8)};
  font-size: ${fluidFontSize([10, 12, 14, 16])};
`

export const TabDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: inherit;
  background-color: ${({ theme }) => theme.colors.grayWithOpacity(0.5)};
`

export const TabContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  max-width: 200px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;
  &:first-child ${TabSeparator} {
    display: none;
  }
  ${({ selected, theme }) =>
    selected &&
    css`
      ${TabLabel} {
        color: ${theme.colors.darkBlue};
      }
      ${TabDot} {
        background-color: ${theme.colors.darkBlue};
      }
    `}
  @media ${({ theme }) => theme.query.sm} {
    margin: 0 10px;
  }
`
