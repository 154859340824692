import styled from 'styled-components'
import { BodyText } from '../../../../style-system/typography'
import { fluidFontSize } from '../../../../style-system/utils'

export const ImagesBox = styled.div`
  width: 40%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media ${({ theme }) => theme.query.md} {
    width: 80%;
    margin-left: 15%;
  }
  @media ${({ theme }) => theme.query.sm} {
    width: 80%;
    height: 220px;
  }
`

export const ImagesContainer = styled.div`
  position: relative;
  width: 90%;
  height: 100%;
  min-width: 280px;
`

export const ServiceImg = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1) url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  &:nth-child(1) {
    z-index: 3;
  }
  &:nth-child(2) {
    top: -18%;
    right: 10%;
    z-index: 2;
  }
  &:nth-child(3) {
    width: 100%;
    height: 90%;
    right: 20%;
    z-index: 1;
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    box-shadow: 0 0 30px ${({ theme }) => theme.colors.darkGrayWithOpacity(0.5)};
  }
`
