import { createRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { getServicesContent } from './Service/content'
import Service from './Service/Service'
import Tabs from './Tabs'
import { GalleryProps } from './types'
import { ServicesSection, Title } from './styles'
import Head from 'next/head'

export const ServicesRef = createRef<any>()

export default function Services() {
  const { t } = useTranslation()

  const [gallery, setGallery] = useState<GalleryProps | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const servicesContent = getServicesContent(t)

  return (
    <>
      <ServicesSection ref={ServicesRef}>
        <Title>{t('services.title')}</Title>
        <Tabs
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
          tabs={servicesContent.map((service) => service.tabTitle)}
        />
        <Carousel
          axis="horizontal"
          autoPlay
          infiniteLoop
          stopOnHover
          useKeyboardArrows
          selectedItem={selectedIndex}
          onChange={setSelectedIndex}
          interval={8000}
          transitionTime={800}
          swipeable={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          emulateTouch={false}
        >
          {servicesContent.map((service) => (
            <Service
              key={service.id}
              {...service}
              onImagesClick={() => {
                setGallery({
                  selectedIndex: 0,
                  images: service.images.map((url) => ({ url }))
                })
              }}
            />
          ))}
        </Carousel>
      </ServicesSection>
      {gallery && (
        <Lightbox
          images={gallery.images}
          startIndex={gallery.selectedIndex}
          onClose={() => setGallery(null)}
        />
      )}
    </>
  )
}

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
}
