import { TFunction } from 'next-i18next'

export const getWhyChooseUsContent = (t: TFunction) => {
  return [
    {
      id: 1,
      iconPath: 'assets/images/location.svg',
      title: t('why_us.everywhere_in_eu.title'),
      description: t('why_us.everywhere_in_eu.description')
    },
    {
      id: 2,
      iconPath: 'assets/images/handshake.svg',
      title: t('why_us.we_are_reliable.title'),
      description: t('why_us.we_are_reliable.description')
    },
    {
      id: 3,
      iconPath: 'assets/images/license.svg',
      title: t('why_us.permanent_license.title'),
      description: t('why_us.permanent_license.description')
    },
    {
      id: 4,
      iconPath: 'assets/images/pig.svg',
      title: t('why_us.affordable_price.title'),
      description: t('why_us.affordable_price.description')
    },
    {
      id: 5,
      iconPath: 'assets/images/badge.svg',
      title: t('why_us.experience.title'),
      description: t('why_us.experience.description')
    }
  ]
}
