import { fontSizeBreakPoints as breakpoints } from './theme'

export const fluidFontSize = (fontSizes: number[]) => `
  font-size: ${fontSizes[0]}px;

  @media screen and (min-width: 0px) {
    font-size: ${fontSizes[0]}px;
  }

  @media screen and (min-width: ${breakpoints[0]}px) {
    font-size: calc(
      ${fontSizes[0]}px + ${fontSizes[1] - fontSizes[0]} *
        ((100vw - ${breakpoints[0]}px) / ${breakpoints[1] - breakpoints[0]})
    );
  }

  @media screen and (min-width: ${breakpoints[1]}px) {
    font-size: calc(
      ${fontSizes[1]}px + ${fontSizes[2] - fontSizes[1]} *
        ((100vw - ${breakpoints[1]}px) / ${breakpoints[2] - breakpoints[1]})
    );
  }
  @media screen and (min-width: ${breakpoints[2]}px) {
    font-size: calc(
      ${fontSizes[2]}px + ${fontSizes[3] - fontSizes[2]} *
        ((100vw - ${breakpoints[2]}px) / ${breakpoints[3] - breakpoints[2]})
    );
  }
  @media screen and (min-width: ${breakpoints[3]}px) {
    font-size: ${fontSizes[3]}px;
  }
`
