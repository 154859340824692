const fontFaces = `
@font-face {
    font-family: 'Brandon';
    src: url(/assets/fonts/Brandon-Regular.otf);
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon-Light';
    src: url('/assets/fonts/Brandon-Light.otf');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon-Bold';
    src: url('/assets/fonts/Brandon-Bold.otf');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon-Italic';
    src: url('/assets/fonts/Brandon-Italic.otf');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon-LightItalic';
    src: url('/assets/fonts/Brandon-LightItalic.otf');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon-BoldItalic';
    src: url('/assets/fonts/Brandon-BoldItalic.otf');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

@font-face {
  font-family: 'LobsterTwo';
  src: url(/assets/fonts/LobsterTwo-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'LobsterTwo-Bold';
  src: url('/assets/fonts/LobsterTwo-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'LobsterTwo-Italic';
  src: url('/assets/fonts/LobsterTwo-Italic.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'LobsterTwo-BoldItalic';
  src: url('/assets/fonts/LobsterTwo-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

`

export default fontFaces
