const breakpoints = ['550px', '900px', '1280px', '1680px']
export const fontSizeBreakPoints = [375, 768, 1024, 1680]

const query = {
  sm: `(max-width: ${breakpoints[0]})`,
  md: `(max-width: ${breakpoints[1]})`,
  l: `(max-width: ${breakpoints[2]})`,
  xl: `(max-width: ${breakpoints[3]})`
}

const theme = {
  colors: {
    white: '#FFFFFF',
    blue: '#0173F5',
    darkBlue: '#07244C',
    nightBlue: '#041227',
    gray: '#404145',
    darkGray: '#333333',
    whiteWithOpacity: (opacity: number = 1) =>
      `rgba(255, 255, 255, ${opacity})`,
    grayWithOpacity: (opacity: number = 1) => `rgba(64, 65, 69, ${opacity})`,
    blueWithOpacity: (opacity: number = 1) => `rgba(1, 115, 245, ${opacity})`,
    darkBlueWithOpacity: (opacity: number = 1) => `rgb(7, 36, 76, ${opacity})`,
    darkGrayWithOpacity: (opacity: number = 1) =>
      `rgba(51, 51, 51, ${opacity})`,
    grayTransparent: 'rgba(64, 65, 69, 0.3)'
  },
  boxShadow: '0px 5px 13px rgba(0, 0, 0, 0.18)',
  borderRadius: '8px',
  breakpoints,
  query,
  fontFamily: {
    primary: 'Brandon'
  },
  spacing: {
    xs: '10px',
    sm: '20px',
    m: '30px',
    md: '40px',
    lg: '60px',
    xl: '70px',
    sectionPaddingX: [10, 10, 40, 70, 0],
    xxl: '120px'
  },
  fontWeights: {
    light: '300',
    regular: '400',
    bold: '700'
  }
}

export default theme
