import { TFunction } from 'next-i18next'

export const getServicesContent = (t: TFunction) => [
  {
    id: 1,
    title: t('services.munkagepek_szallitasa.title'),
    tabTitle: t('services.munkagepek_szallitasa.tab_title'),
    description: t<string[]>('services.munkagepek_szallitasa.description', {
      returnObjects: true
    }),
    images: [
      '/assets/images/services/munkagep_szallitas1.webp',
      '/assets/images/services/munkagep_szallitas2.webp',
      '/assets/images/services/munkagep_szallitas3.webp'
    ]
  },
  {
    id: 2,
    title: t('services.kamionok_szallitasa.title'),
    tabTitle: t('services.kamionok_szallitasa.tab_title'),
    description: t<string[]>('services.kamionok_szallitasa.description', {
      returnObjects: true
    }),
    images: [
      '/assets/images/services/kamion_szallitas2.webp',
      '/assets/images/services/kamion_szallitas3.webp',
      '/assets/images/services/kamion_szallitas1.webp',
      '/assets/images/services/kamion_szallitas4.webp'
    ]
  },
  {
    id: 3,
    title: t('services.mezogazdasagi_gepek_szallitasa.title'),
    tabTitle: t('services.mezogazdasagi_gepek_szallitasa.tab_title'),
    description: t<string[]>(
      'services.mezogazdasagi_gepek_szallitasa.description',
      { returnObjects: true }
    ),
    images: [
      '/assets/images/services/mezogazdasagi_gep_szallitas1.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas4.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas3.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas2.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas5.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas6.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas7.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas8.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas9.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas10.webp',
      '/assets/images/services/mezogazdasagi_gep_szallitas11.webp'
    ]
  },
  {
    id: 4,
    title: t('services.tulmeretes_szallitas.title'),
    tabTitle: t('services.tulmeretes_szallitas.tab_title'),
    description: t<string[]>('services.tulmeretes_szallitas.description', {
      returnObjects: true
    }),
    images: [
      '/assets/images/services/tulmeretes_szallitas10.webp',
      '/assets/images/services/tulmeretes_szallitas2.webp',
      '/assets/images/services/tulmeretes_szallitas3.webp',
      '/assets/images/services/tulmeretes_szallitas4.webp',
      '/assets/images/services/tulmeretes_szallitas5.webp',
      '/assets/images/services/tulmeretes_szallitas6.webp',
      '/assets/images/services/tulmeretes_szallitas7.webp',
      '/assets/images/services/tulmeretes_szallitas8.webp',
      '/assets/images/services/tulmeretes_szallitas9.webp',
      '/assets/images/services/tulmeretes_szallitas1.webp'
    ]
  },
  {
    id: 5,
    title: t('services.hosszu_aruk_hidelemek_szallitasa.title'),
    tabTitle: t('services.hosszu_aruk_hidelemek_szallitasa.tab_title'),
    description: t<string[]>(
      'services.hosszu_aruk_hidelemek_szallitasa.description',
      { returnObjects: true }
    ),
    images: [
      '/assets/images/services/hosszu_aru_szallitas1.webp',
      '/assets/images/services/hosszu_aru_szallitas4.webp',
      '/assets/images/services/hosszu_aru_szallitas5.webp',
      '/assets/images/services/hosszu_aru_szallitas3.webp',
      '/assets/images/services/hosszu_aru_szallitas2.webp'
    ]
  }
]
