import { TabsContainer } from './styles'
import Tab from './Tab'

type Props = {
  tabs: string[]
  selectedIndex: number
  onChange: (index: number) => void
}

const Tabs: React.FC<Props> = ({ tabs, selectedIndex, onChange }) => (
  <TabsContainer>
    {tabs.map((tab, index) => (
      <Tab
        key={tab}
        label={tab}
        selected={index === selectedIndex}
        onClick={() => onChange(index)}
      />
    ))}
  </TabsContainer>
)

export default Tabs
