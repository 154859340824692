import React, { createRef } from 'react'
import { SectionTitle } from '../styles.util'
import {
  ContactDetails,
  Container,
  Content,
  DetailColumn,
  DetailRow,
  Details,
  DetailsTitle,
  Footer,
  GarageDetails,
  HeadquartersDetails,
  IconText,
  OfferRequest
} from './styles'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import { Trans, useTranslation } from 'next-i18next'

export const ContactRef = createRef<any>()

const taxNumber = '23424843243'
const companyRegistrationNumber = '01 09 381672'
const foundationYear = '2011'

export default function Contact() {
  const { t } = useTranslation()

  const phone = t('contact.phone')
  const email = t('contact.email')

  return (
    <Container ref={ContactRef}>
      <SectionTitle>{t('contact.title')}</SectionTitle>
      <Content>
        <OfferRequest>
          <Trans
            i18nKey="contact.description" // optional -> fallbacks to defaults if not provided
            values={{ phone, email }}
            components={{
              phonelink: <a href={`tel:${phone}`} />,
              emaillink: <a href={`mailto:${email}`} />
            }}
          />
        </OfferRequest>
        <ContactDetails>
          <HeadquartersDetails>
            <DetailsTitle>{t('contact.location')}:</DetailsTitle>
            <Details>
              <iframe
                id="map-office"
                title="map-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2698.8639896165273!2d19.1691204156245!3d47.43409537917356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c268eb3ce8fd%3A0xf1c79b1d66205bb6!2sBudapest%2C%20V%C3%A1ndor%20S%C3%A1ndor%20u.%203%2C%201181!5e0!3m2!1shu!2shu!4v1657466716703!5m2!1shu!2shu"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
              <DetailColumn>
                <DetailRow>
                  <IconText>
                    <LocationOnIcon />
                    <div>
                      <div>Morzika Kft.</div>
                      <div>{t('contact.address')}</div>
                      <div>
                        <span>{t('contact.foundation_year')}</span>{' '}
                        {foundationYear}
                      </div>
                      <div>
                        <span>{t('contact.tax_number')}:</span> {taxNumber}
                      </div>
                      <div>
                        <span>{t('contact.company_registration_number')}:</span>{' '}
                        {companyRegistrationNumber}
                      </div>
                    </div>
                  </IconText>
                </DetailRow>
                <DetailRow>
                  <IconText>
                    <PhoneIcon />
                    {phone}
                  </IconText>
                </DetailRow>
                <DetailRow>
                  <IconText>
                    <EmailIcon />
                    {email}
                  </IconText>
                </DetailRow>
              </DetailColumn>
            </Details>
          </HeadquartersDetails>
        </ContactDetails>
        <Footer>
          Geptrans.com - Morzika Kft. - {t('contact.address')} -{' '}
          {t('contact.tax_number')}: {taxNumber}.
        </Footer>
      </Content>
    </Container>
  )
}
