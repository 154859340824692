import { MouseEventHandler } from 'react'
import { TabContainer, TabDot, TabLabel, TabSeparator } from './styles'

type Props = {
  label: string
  selected: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Tab: React.FC<Props> = ({ label, selected, onClick }) => (
  <TabContainer selected={selected} onClick={onClick}>
    <TabSeparator />
    <TabLabel>{label}</TabLabel>
    <TabDot />
  </TabContainer>
)

export default Tab
