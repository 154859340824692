import styled from 'styled-components'
import { BodyText, H1, H2, H3 } from '../../../style-system/typography'
import { fluidFontSize } from '../../../style-system/utils'

export const ServiceCarousel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ServiceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 30px 0;
  text-align: left;
  min-height: 60vh;
  @media ${({ theme }) => theme.query.md} {
    flex-direction: column-reverse;
    gap: 80px;
    width: 100%;
    margin-top: 0;
  }
`

export const ServiceContent = styled.div`
  width: 50%;
  align-self: flex-start;
  padding: 20px 30px;
  margin-top: 30px;
  @media ${({ theme }) => theme.query.md} {
    width: 100%;
    margin-top: 0;
  }
`

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.darkBlue};
  @media ${({ theme }) => theme.query.md} {
    text-align: center;
  }
`

export const FullDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const Text = styled(BodyText)`
  font-size: ${fluidFontSize([16, 16, 16, 18])};
  color: ${({ theme }) => theme.colors.grayWithOpacity(0.8)};
`

export const DescriptonText = styled.div`
  display: flex;
  margin: 10px 0;
  ${Text} {
    margin: 0;
  }
  svg {
    width: 12px;
    height: 12px;
    margin-top: 5px;
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`
