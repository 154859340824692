import styled from 'styled-components'
import { fluidFontSize } from '../../style-system/utils'
import { SectionContainer, SectionTitle } from '../styles.util'

export const ReferencesSection = styled(SectionContainer)`
  padding: 40px 0 80px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background: ${({ theme }) => theme.colors.white};
  @media ${({ theme }) => theme.query.md} {
    gap: 40px;
  }
`

export const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const CardsContainer = styled.div`
  width: 80%;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  @media ${({ theme }) => theme.query.l} {
    width: 60%;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.query.md} {
    width: 80%;
  }
  @media ${({ theme }) => theme.query.sm} {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`

export const ReferenceImg = styled.div<{ bgImg: string }>`
  width: 130px;
  height: 130px;
  align-self: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.grayWithOpacity(0.2)}
    url(${({ bgImg }) => bgImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray};
  & .title {
    margin-top: 6px;
    font-weight: bold;
    font-size: ${fluidFontSize([16, 16, 18, 20])};
  }
  & .subtitle {
    margin-top: 6px;
    margin-bottom: 12px;
    font-size: ${fluidFontSize([14, 14, 16, 16])};
    color: #005cc5;
  }
  & .message {
    font-size: ${fluidFontSize([16, 16, 18, 18])};
    color: ${({ theme }) => theme.colors.grayWithOpacity(0.8)};
  }
`
