import { createGlobalStyle } from 'styled-components'
import fontFaces from './font-faces'
import theme from './theme'

const GlobalStyle = createGlobalStyle<{ theme: typeof theme }>`
  ${fontFaces}
  html {
    scroll-behavior: smooth;
    font-family: Brandon;
  }
  body {
    padding: 0;
    box-sizing: border-box;
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.white};
    margin: 0 auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  * {
    box-sizing: border-box;
  }
`

export default GlobalStyle
