import React, { RefObject, useRef, useState } from 'react'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PhoneIcon from '@mui/icons-material/Phone'
import useScroll from '../../hooks/useScroll'
import {
  Hamburger,
  HeaderWrapper,
  MenuButton,
  NavbarItem,
  NavbarItemGroup,
  NavbarItems
} from './styles'
import useClickAway from '../../hooks/useClickAway.js'
import { useTranslation } from 'react-i18next'
import { WhyUsRef } from '../WhyUs/WhyUs'
import { ServicesRef } from '../Services/Services'
import { ReferencesRef } from '../References/References'
import { ContactRef } from '../Contact/Contact'

export default function Header() {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOnTop, setIsOntTop] = useState(true)

  const navRef = useRef(null)

  useClickAway(navRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  })

  useScroll(() => {
    if (window.scrollY > 0 && isOnTop) {
      setIsOntTop(false)
    } else if (window.scrollY <= 0 && !isOnTop) {
      setIsOntTop(true)
    }
  })

  const scrollTo = (ref: React.MutableRefObject<any>) => {
    if (ref.current === null) {
      return
    }

    window.scrollTo(0, ref.current.offsetTop - 60)
  }

  const onMenuItemClick = (ref: RefObject<any>) => {
    scrollTo(ref)
    setIsMenuOpen(false)
  }

  return (
    <HeaderWrapper ref={navRef} isOnTop={isOnTop}>
      <NavbarItems open={isMenuOpen}>
        <NavbarItemGroup>
          <NavbarItem>
            <PhoneIcon />
            <a href={`tel:${t('contact.phone')}`}>{t('contact.phone')}</a>
          </NavbarItem>
          <NavbarItem>
            <AlternateEmailIcon />
            <a href={`mailto:${t('contact.email')}`}>{t('contact.email')}</a>
          </NavbarItem>
        </NavbarItemGroup>
        <NavbarItemGroup>
          <NavbarItem onClick={() => onMenuItemClick(ServicesRef)}>
            {t('navbar.services')}
          </NavbarItem>
          <NavbarItem onClick={() => onMenuItemClick(WhyUsRef)}>
            {t('navbar.why-us')}
          </NavbarItem>
          <NavbarItem onClick={() => onMenuItemClick(ReferencesRef)}>
            {t('navbar.references')}
          </NavbarItem>
          <NavbarItem onClick={() => onMenuItemClick(ContactRef)}>
            {t('navbar.contact')}
          </NavbarItem>
        </NavbarItemGroup>
      </NavbarItems>
      <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Hamburger open={isMenuOpen} />
      </MenuButton>
    </HeaderWrapper>
  )
}
