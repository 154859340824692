import { useTranslation } from 'next-i18next'
import { createRef } from 'react'
import { getWhyChooseUsContent } from './content'
import {
  Icon,
  IconContainer,
  ItemCard,
  ItemText,
  ItemTitle,
  Title,
  WhyUsContainer,
  WhyUsSection
} from './styles'

export const WhyUsRef = createRef<any>()

export default function WhyUs() {
  const { t } = useTranslation()

  const content = getWhyChooseUsContent(t)

  return (
    <WhyUsSection ref={WhyUsRef}>
      <WhyUsContainer>
        <Title>{t('why_us.title')}</Title>
        {content.map((item) => (
          <ItemCard key={item.id}>
            <IconContainer>
              <Icon src={item.iconPath} alt={item.title} />
            </IconContainer>
            <ItemTitle>{item.title}</ItemTitle>
            <ItemText>{item.description}</ItemText>
          </ItemCard>
        ))}
      </WhyUsContainer>
    </WhyUsSection>
  )
}
